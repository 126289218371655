<template>
  <div class="editable-list">
    <sm-breadcrumbs :items="breadcrumbs" class="editable-list__breadcrumbs" />

    <div class="meter-recheck-requests__filters">
      <sm-datepicker
        v-model="date"
        label="Дата"
        type="date"
        format="DD.MM.YYYY"
        valueType="DD.MM.YYYY"
        timeTitleFormat="DD.MM.YYYY"
        :range="true"
      />

      <sm-select
        v-model="withDateNextRecheck"
        label="Указана ли дата поверки"
        :options="[
          { value: null, name: 'Все' },
          { value: true, name: 'С указанной датой поверки' },
          { value: false, name: 'Без указанной даты поверки' },
        ]"
      />
    </div>

    <sm-button
      :isLoading="isLoadingPage"
      colorType="primary"
      @click="onFetch"
      class="meter-recheck-requests__button"
    >
      Применить
    </sm-button>

    <div class="meter-recheck-requests__datatable">
      <sm-page-loader v-if="isLoadingPage" />

      <sm-datatable
        :caption="tableCaption"
        :headers="tableHeaders"
        :items="items"
      >
        <template v-slot:act="{ row }">
          <a class="editable-list__link" @click="onDownloadAct(row.bduId)">
            {{ row.act }}
          </a>
        </template>

        <template #actions="{ row }">
          <button
            class="editable-list__datatable-button"
            @click="onEdit('MeterRecheckRequestsEdit', row.bduId)"
          >
            <sm-icon name="eye" />
          </button>
        </template>
      </sm-datatable>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';

// mixins
import editableList from '@/mixins/editableList.js';

// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatepicker from '@/components/common/forms/SmDatepicker.vue';
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'MeterRecheckRequests',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmDatepicker,
    SmSelect,
    SmDatatable,
    SmIcon,
    SmButton,
  },

  mixins: [editableList],

  data() {
    return {
      isLoadingPage: false,
      tableCaption: 'Заявки на поверку ИПУ',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Заявки на поверку ИПУ',
        },
      ],
      tableHeaders: [
        {
          text: 'Имя БД',
          order: 'baseName',
          alias: 'baseName',
        },
        {
          text: 'Дата заявки',
          order: 'added',
          alias: 'added',
        },
        {
          text: 'Номер заявки в АДС',
          order: 'requestNumber',
          alias: 'requestNumber',
        },
        {
          text: 'ЛС',
          order: 'ident',
          alias: 'ident',
        },
        {
          text: 'Адрес',
          order: 'address',
          alias: 'address',
        },
        {
          text: 'Телефон автора',
          order: 'authorPhone',
          alias: 'authorPhone',
        },
        {
          text: 'Организация',
          order: 'company',
          alias: 'company',
        },
        {
          text: 'Статус заявки',
          order: 'status',
          alias: 'status',
        },
        {
          text: 'Статус ДБУ',
          order: 'statusBdu',
          alias: 'statusBdu',
        },
        {
          text: 'Акт',
          order: 'act',
          alias: 'act',
        },
        {
          alias: 'actions',
        }
      ],
      date: '',
      withDateNextRecheck: null,
      items: [],
    };
  },

  computed: {
    ...mapState({
      data: (state) => state.meterRecheckRequests.data,
      filters: (state) => state.meterRecheckRequests.filters,
    }),
  },

  created() {
    this.date = this.filters && this.filters.DateFrom && this.filters.DateTo
      ? this.filters.DateFrom.split(' ')[0] + '-' + this.filters.DateTo.split(' ')[0]
      : '';
    this.withDateNextRecheck = this.filters ? this.filters.WithDateNextRecheck : null;
    this.items = this.data ? this.data : [];
  },

  methods: {
    ...mapActions({
      meterRecheckRequests: 'meterRecheckRequests/meterRecheckRequests',
      downloadAct: 'meterRecheckRequests/downloadAct',
    }),

    onFetch() {
      this.isLoadingPage = true;

      this.meterRecheckRequests({
        DateFrom: this.date && this.date.split('-')[0] ? this.date.split('-')[0] + ' 00:00:00' : '',
        DateTo: this.date && this.date.split('-')[1] ? this.date.split('-')[1] + ' 23:59:59' : '',
        WithDateNextRecheck: this.withDateNextRecheck,
      }).then((data) => {
        this.items = data;
      })
      .finally(() => {
        this.isLoadingPage = false;
      });
    },

    onDownloadAct(bduId) {
      this.downloadAct({
        bduId,
      });
    },
  },
};
</script>

<style lang="scss">
.editable-list {
  &__breadcrumbs {
    margin-bottom: 15px;
  }

  &__datatable-button {
    font-size: 0;
    color: var(--gray);
  }

  &__link {
    text-decoration: underline;
    color: var(--blue);
    cursor: pointer;
  }
}

.meter-recheck-requests {
  &__filters {
    display: flex;
    margin-bottom: 20px;

    .datepicker {
      margin-right: 15px;
      width: 250px;
    }

    .select__wrapper {
      width: 270px;

      .select__label {
        margin-bottom: 10px;
      }
    }
  }

  &__button {
    margin-bottom: 20px;
    width: 180px;
  }

  &__datatable {
    position: relative;

    .page-loader {
      position: absolute;
    }
  }
}
</style>
